#navBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  background-color: #000000;
  color: #13dafb;
  width: 100vw;
  max-height: 90px;
  .logoDiv {
    max-width: 50%;
    max-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .logo {
      padding: 10px 10px 10px 10px;
      max-width: 100%;
      max-height: auto;
    }
  }
  #LoginButton {
    max-width: 30%;
    max-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    .login {
      padding: 10px 10px 10px 10px;
      width: 45px;
      height: 45px;
    }
    .login:hover {
      transform: scale(1.2);
    }
  }
}

#sideBar {
  #backButtonDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    .backButton {
      margin: 10px 10px 10px 10px;
      padding: 1px 1px 1px 1px;
      width: 100px;
      background-color: #000000;
      outline: none;
      border: 1px solid #13dafb;
      color: #13dafb;
    }
    .backButton:hover {
      background-color: #13dafb;
      color: #000000;
    }
  }
}

@media only screen and (max-width: 649px) {
  #masterContainer {
    max-width: 100%;
    position: relative;
    .backDropContainer {
      width: 100%;
      max-height: 185px;
      overflow: hidden;
      .backDrop {
        width: 100%;
        opacity: 1;
      }
    }
    #singleMovieContainer {
      max-width: 100%;
      .moviePosterDiv {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 35%;
        .moviePoster {
          max-width: 100px;
          width: 100%;
          height: auto;
          z-index: 1;
          border-radius: 10px;
          box-shadow: 5px 5px 5px #000000;
        }
        .singleMratingBar {
          position: absolute;
          width: 30px;
          height: 30px;
          top: 135px;
          left: 5px;
          .CircularProgressbar {
            stroke: #13dafb;
            .CircularProgressbar-background {
              fill: #000000;
            }
            .CircularProgressbar-trail {
              stroke: #000000;
            }
            .CircularProgressbar-path {
              stroke: #13dafb;
            }
            .CircularProgressbar-text {
              stroke: #13dafb;
              font-size: 24px;
            }
          }
        }
      }

      .singleMovieInfoContainer {
        max-width: 100%;
        .movieTitleDiv {
          padding: 10px 10px 10px 10px;
          .movieTitle {
            margin: 0;
          }
        }
        .subInfoDiv {
          padding: 10px 10px 10px 10px;
          max-width: 100%;
          width: max-content;
          display: flex;
          flex-direction: row;
          .cert {
            margin: 0;
            border-style: solid;
            padding: 1px 1px 1px 1px;
          }
          .moreInfo {
            margin: 0;
            padding: 4px 4px 4px 4px;
            li {
              list-style: none;
              display: inline;
              margin-left: 5px;
            }
            li:after {
              content: " \2022";
            }
            li:last-child:after {
              content: none;
            }
          }
        }
        .taglineDiv {
          padding: 10px 10px 10px 10px;
          .tagLine {
            margin: 0;
          }
        }
        .overviewTitleDiv {
          padding: 10px 10px 10px 10px;
          font-size: 16px;
          h2 {
            margin: 0;
          }
        }
        .movieOverviewDiv {
          padding: 10px 10px 10px 10px;
          font-size: 14px;
          .movieOverview {
            margin: 0;
          }
        }
        .interactionContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          height: 50px;
          background-color: #000000;
          padding: 10px 20px 10px 20px;
          #ratingBar {
            display: none;
          }
          .ratingsDiv {
            height: 40%;
            position: relative;
            .ratings {
              height: 100%;
              cursor: pointer;
            }
            .ratings:hover {
              transform: scale(1.1);
            }
          }
          .favouriteDiv {
            height: 40%;
            .favourite {
              height: 100%;
              cursor: pointer;
            }
          }
          .favouriteDiv:hover {
            transform: scale(1.1);
          }
          .playButtonDiv {
            height: 40%;
            display: flex;
            flex-direction: row;
            .playButton {
              height: 100%;
              cursor: pointer;
              color: #13dafb;
            }
            .wording {
              height: 100%;
              display: flex;
              align-items: center;
              p {
                color: #13dafb;
                cursor: pointer;
                margin: 0;
                padding: 0 0 0 2px;
                font-size: 16px;
              }
            }
          }
          .playButtonDiv:hover {
            transform: scale(1.1);
          }
        }
      }
    }
    .overviewTitleDiv2 {
      display: none;
    }
    .movieOverviewDiv2 {
      display: none;
    }
    .interactionContainer {
      display: none;
    }
  }
  #relFilmHeading {
    padding: 10px 10px 10px 10px;
    p {
      margin: 0;
    }
  }
  .relatedFilmsDiv {
    display: flex;
    flex-direction: row;
    overflow: scroll;
  }
}

@media only screen and (min-width: 650px) {
  #masterContainer {
    width: 100%;
    position: relative;
    height: auto;
    .backDropContainer {
      width: 100%;
      max-height: 530px;
      overflow: hidden;
      background-color: #000000;
      .backDrop {
        width: 100%;
        opacity: 0.4;
        border-top-left-radius: 10px;
      }
    }
    #singleMovieContainer {
      padding: 10px 10px 10px 10px;
      display: flex;
      flex-direction: row;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      max-height: 100%;
      border-top-left-radius: 10%;
      color: #ffffff;
      .moviePosterDiv {
        max-width: 35%;
        padding: 10px 10px 10px 0;
        position: relative;
        .moviePoster {
          max-width: 300px;

          width: 100%;
          height: auto;
          z-index: 1;
          border-radius: 10px;
          box-shadow: 5px 5px 5px #000000;
        }
        .singleMratingBar {
          display: none;
        }
      }

      .singleMovieInfoContainer {
        padding: 10px 10px 10px 10px;
        display: flex;
        flex-direction: column;
        max-width: 65%;
        height: 100%;
        .movieTitleDiv {
          padding: 10px 10px 10px 10px;
          .movieTitle {
            margin: 0;
            font-size: 32px;
          }
        }
        .subInfoDiv {
          padding: 10px 10px 10px 10px;
          width: max-content;
          display: flex;
          flex-direction: row;
          .cert {
            margin: 0;
            border-style: solid;
            padding: 1px 1px 1px 1px;
            font-size: 18px;
          }
          .moreInfo {
            margin: 0;
            padding: 4px 4px 4px 4px;
            li {
              list-style: none;
              display: inline;
              margin-left: 5px;
              font-size: 16px;
            }
            li:after {
              content: " \2022";
            }
            li:last-child:after {
              content: none;
            }
          }
        }
        .taglineDiv {
          padding: 10px 10px 10px 10px;
          .tagLine {
            margin: 0;
            font-size: 16px;
          }
        }
        .overviewTitleDiv {
          display: inline-block;
          padding: 10px 10px 10px 10px;
          font-size: 16px;
          h2 {
            margin: 0;
            font-size: 24px;
          }
        }
        .movieOverviewDiv {
          padding: 10px 10px 10px 10px;
          font-size: 14px;
          display: inline-block;
          .movieOverview {
            margin: 0;
            font-size: 14px;
          }
        }
        .interactionContainer {
          display: none;
        }
      }
    }
    .overviewTitleDiv2 {
      display: none;
    }
    .movieOverviewDiv2 {
      display: none;
    }
    .interactionContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      background-color: #000000;
      padding: 10px 20px 10px 20px;
      #ratingBar {
        width: 50px;
        height: 50px;
        .CircularProgressbar {
          stroke: #13dafb;
          .CircularProgressbar-background {
            fill: #000000;
          }
          .CircularProgressbar-trail {
            stroke: #000000;
          }
          .CircularProgressbar-path {
            stroke: #13dafb;
          }
          .CircularProgressbar-text {
            stroke: #13dafb;
            fill: #13dafb;
            font-size: 16px;
          }
        }
      }
      .ratingsDiv {
        height: 60%;
        position: relative;
        .ratings {
          height: 100%;
          cursor: pointer;
        }
        .ratings:hover {
          transform: scale(1.1);
        }
      }
      .favouriteDiv {
        height: 60%;
        .favourite {
          height: 100%;
          cursor: pointer;
        }
      }
      .favouriteDiv:hover {
        transform: scale(1.1);
      }
      .playButtonDiv {
        height: 60%;
        display: flex;
        flex-direction: row;
        .playButton {
          height: 100%;
          cursor: pointer;
          color: #13dafb;
        }
        .wording {
          height: 100%;
          display: flex;
          align-items: center;
          p {
            color: #13dafb;
            cursor: pointer;
            margin: 0;
            padding: 0 0 0 2px;
            font-size: 16px;
          }
        }
      }
      .playButtonDiv:hover {
        transform: scale(1.1);
      }
    }
  }
  #relFilmHeading {
    padding: 10px 10px 10px 10px;
    p {
      margin: 0;
    }
  }
  .relatedFilmsDiv {
    display: flex;
    flex-direction: row;
    overflow: scroll;
  }
}

@media only screen and (min-width: 1120px) {
  #masterContainer {
    #singleMovieContainer {
      .singleMovieInfoContainer {
        .interactionContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          height: 50px;
          background-color: transparent;
          padding: 10px 20px 10px 10px;
          .playButtonDiv {
            height: 100%;
            display: flex;
            flex-direction: row;
            .playButton {
              height: 100%;
              cursor: pointer;
            }
            p {
              cursor: pointer;
            }
          }
        }
      }
    }
    .interactionContainer {
      display: none;
    }
  }
}

@media only screen and (max-width: 899px) {
  .popularHeadersDiv {
    display: flex;
    flex-direction: row;
    padding: 10px 10px 10px 10px;
    font-size: 16px;
    cursor: default;

    .popular {
      background-color: #13dafb;

      .subHeading {
        padding: 10px 10px 10px 10px;
        font-size: 16px;
      }
    }
    .favourite {
      .subHeading {
        padding: 10px 10px 10px 10px;
        font-size: 16px;
      }
    }
    .favourite:hover {
      transform: scale(1.1);
    }
  }
  .movie {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 5px 5px 5px 5px;
    position: relative;
    justify-self: center;
    max-width: 100px;
    .moviePoster {
      max-width: 100px;
      height: auto;
      border-radius: 5%;
      box-shadow: 5px 5px 5px #000000;
    }
    .moviePoster:hover {
      transform: scale(1.1);
    }
    .movieTitle {
      padding-top: 10px;
      font-size: 12px;
    }
  }
  .ratingBar {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 148px;
    left: 5px;
    .CircularProgressbar {
      stroke: #13dafb;
      .CircularProgressbar-background {
        fill: #000000;
      }
      .CircularProgressbar-trail {
        stroke: #000000;
      }
      .CircularProgressbar-path {
        stroke: #13dafb;
      }
      .CircularProgressbar-text {
        stroke: #13dafb;
        font-size: 16px;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .popularHeadersDiv {
    display: flex;
    flex-direction: row;
    padding: 10px 10px 10px 10px;
    font-size: 16px;
    cursor: default;

    .popular {
      background-color: #13dafb;
      border-top-left-radius: 20px;
      .subHeading {
        padding: 10px 10px 10px 10px;
        font-size: 16px;
      }
    }
    .favourite {
      .subHeading {
        padding: 10px 10px 10px 10px;
        font-size: 16px;
      }
    }
    .favourite:hover {
      transform: scale(1.1);
    }
  }
  .movie {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 10px 10px 10px 10px;
    max-width: 185px;
    position: relative;
    .moviePoster {
      max-width: 185px;
      max-height: 278px;
      border-radius: 5%;
      box-shadow: 5px 5px 5px #000000;
    }
    .moviePoster:hover {
      transform: scale(1.1);
    }
    .movieTitle {
      font-size: 16px;
      padding-top: 10px;
    }
  }
  .ratingBar {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 270px;
    left: 5px;
    .CircularProgressbar {
      stroke: #13dafb;
      .CircularProgressbar-background {
        fill: #000000;
      }
      .CircularProgressbar-trail {
        stroke: #000000;
      }
      .CircularProgressbar-path {
        stroke: #13dafb;
      }
      .CircularProgressbar-text {
        stroke: #13dafb;
        font-size: 24px;
      }
    }
  }
}

@media only screen and (max-width: 899px) {
  #sideBar {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    justify-content: space-between;
    background-color: #000000;
    color: #13dafb;
    .searchBoxDiv {
      text-align: left;
      padding: 10px 10px 10px 10px;
      #searchLabel {
        width: 100%;
        font-size: 16px;
        text-align: center;
        padding-right: 10px;
      }
      .searchInputs {
        display: flex;
        flex-direction: row;
        #movieSearch {
          width: 30%;
          font-size: 16px;
          padding: 5px;
          border: 1px;
          border-style: solid;
          border-color: #13dafb;
        }
        #movieSearch:focus {
          outline: none;
          border: 1px solid #13dafb;
          box-shadow: 0 0 10px #13dafb;
        }
        #searchReset {
          background-color: #000000;
          outline: none;
          border: 1px solid #13dafb;
          color: #13dafb;
        }
        #searchReset:hover {
          background-color: #13dafb;
          color: #000000;
        }
      }
    }
    #showCollapsible {
      padding: 10px 10px 10px 10px;
      .showCollapsible {
        text-align: left;
        background-color: #000000;
        color: #13dafb;
        font-size: 16px;
        padding: 5px;
        border: 1px;
        border-color: #13dafb;
        border-style: solid;
      }
      .showCollapsible:hover {
        background-color: #13dafb;
        color: #000000;
      }
    }
    #hideCollapsible {
      padding: 10px 10px 10px 10px;
      .hideCollapsible {
        text-align: left;
        background-color: #000000;
        color: #13dafb;
        font-size: 16px;
        padding: 5px;
        border: 1px;
        border-color: #13dafb;
        border-style: solid;
      }
      .hideCollapsible:hover {
        background-color: #13dafb;
        color: #000000;
      }
      .checkBoxDiv {
        padding: 10px 10px 10px 10px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        label {
          font-size: 16px;
          padding: 1px 1px 1px 1px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-right: 10px;
          input {
            width: 15px;
            height: 15px;
            background-color: white;
            border-radius: 50%;
            vertical-align: middle;
            border: 1px solid #ddd;
            appearance: none;
            -webkit-appearance: none;
            outline: none;
            cursor: pointer;
          }
          input:checked {
            background-color: #13dafb;
          }
        }
      }
      .clearCheckBox {
        button {
          padding: 1px 5px 0px 5px;
          text-align: center;
          background-color: #000000;
          color: #13dafb;
          font-size: 16px;
          border: 1px;
          border-color: #13dafb;
          border-style: solid;
        }
        button:hover {
          background-color: #13dafb;
          color: #000000;
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  #sideBar {
    margin: 0;
    padding: 10px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    width: 17vw;
    height: calc(100vh - 110px);
    background-color: #000000;
    color: #13dafb;
    text-align: left;
    .searchBoxDiv {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      #searchLabel {
        padding-bottom: 10px;
      }
      .searchInputs {
        display: flex;
        flex-direction: row;
        #movieSearch {
          width: 80%;
          font-size: 16px;
          padding: 5px;
          border: 1px;
          border-style: solid;
          border-color: #13dafb;
        }
        #movieSearch:focus {
          outline: none;
          border: 1px solid #13dafb;
          box-shadow: 0 0 10px #13dafb;
        }
        #searchReset {
          background-color: #000000;
          outline: none;
          border: 1px solid #13dafb;
          color: #13dafb;
        }
        #searchReset:hover {
          background-color: #13dafb;
          color: #000000;
        }
      }
    }
    #showCollapsible {
      padding: 10px 10px 10px 0px;
      .showCollapsible {
        text-align: left;
        background-color: #000000;
        color: #13dafb;
        font-size: 16px;
        padding: 5px;
        border: 1px;
        border-color: #13dafb;
        border-style: solid;
        width: 85%;
      }
      .showCollapsible:hover {
        background-color: #13dafb;
        color: #000000;
      }
    }
    #hideCollapsible {
      padding: 10px 10px 10px 0px;
      .hideCollapsible {
        text-align: left;
        background-color: #000000;
        color: #13dafb;
        font-size: 16px;
        padding: 5px;
        border: 1px;
        border-color: #13dafb;
        border-style: solid;
        width: 85%;
      }
      .hideCollapsible:hover {
        background-color: #13dafb;
        color: #000000;
      }
      .checkBoxDiv {
        padding: 10px 0px 10px 0px;
        display: flex;
        flex-direction: column;
        label {
          font-size: 16px;
          padding: 1px 0px 1px 0px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          input {
            width: 1.3em;
            height: 1.3em;
            background-color: white;
            border-radius: 50%;
            vertical-align: middle;
            border: 1px solid #ddd;
            appearance: none;
            -webkit-appearance: none;
            outline: none;
            cursor: pointer;
          }
          input:checked {
            background-color: #13dafb;
          }
        }
        label:last-child {
          padding-top: 9px;
        }
        .clearCheckBox {
          button {
            padding: 5px;
            text-align: left;
            background-color: #000000;
            color: #13dafb;
            font-size: 16px;
            border: 1px;
            border-color: #13dafb;
            border-style: solid;
          }
          button:hover {
            background-color: #13dafb;
            color: #000000;
          }
        }
      }
    }
  }
}

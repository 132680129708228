@media only screen and (min-width: 1px) {
  .loginModalContainer {
    max-width: 80%;
    max-height: 80%;
    background-color: #ffffff;
    border-style: solid;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
    .modal {
      .btnContainer {
        height: 20px;
        button {
          background-color: #000000;
          outline: none;
          border: 1px solid #13dafb;
          color: #13dafb;
        }
        button:hover {
          background-color: #13dafb;
          color: #000000;
        }
      }
      .formTitle {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
      }
      .loginContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        padding: 10px;
        .unameLabel {
          font-weight: bold;
          padding: 10px 0px 10px 0px;
        }
        .unameInput {
          width: 80%;
          font-size: 16px;
        }
        .pswLabel {
          font-weight: bold;
          padding: 10px 0px 10px 0px;
        }
        .pswInput {
          width: 80%;
          font-size: 16px;
        }
        .message {
          margin: 10px 0px 0px 0px;
          color: #ff0000;
        }
        .loginButtonDiv {
          padding: 10px 0px 10px 0px;
          width: 60%;
          .loginButton {
            font-weight: bold;
            background-color: #000000;
            color: #13dafb;
            outline: none;
            border: 1px solid #13dafb;
            width: 100%;
            font-size: 16px;
          }
          .loginButton:hover {
            background-color: #13dafb;
            color: #000000;
          }
        }
        p {
          margin: 0;
        }
        .pswLabel2 {
          font-weight: bold;
          padding: 10px 0px 10px 0px;
        }
        .pswInput2 {
          width: 80%;
          font-size: 16px;
        }
        .message2 {
          margin: 10px 0px 0px 0px;
          color: #ff0000;
        }
        .registerButtonDiv {
          padding: 10px 0px 10px 0px;
          width: 60%;
          .registerButton {
            font-weight: bold;
            background-color: #000000;
            color: #13dafb;
            outline: none;
            border: 1px solid #13dafb;
            width: 100%;
            font-size: 16px;
          }
          .registerButton:hover {
            background-color: #13dafb;
            color: #000000;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 480px) {
  .loginModalContainer {
    max-width: 60%;
    max-height: 80%;
  }
}

@media only screen and (min-width: 600px) {
  .loginModalContainer {
    max-width: 50%;
    max-height: 80%;
  }
}

@media only screen and (min-width: 720px) {
  .loginModalContainer {
    max-width: 40%;
    max-height: 80%;
  }
}

@media only screen and (min-width: 900px) {
  .loginModalContainer {
    max-width: 30%;
    max-height: 80%;
  }
}

@media only screen and (max-width: 899px) {
  .modalContainer {
    // width: 300px;
    // height: 188px;
    max-width: 90%;
    aspect-ratio: 25/14;
    background-color: white;
    border-style: solid;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
    .modal {
      background-color: #ffffff;
      .btnContainer {
        height: 40px;
        button {
          background-color: #000000;
          outline: none;
          border: 1px solid #13dafb;
          color: #13dafb;
          height: 40px;
          width: 40px;
          font-size: 33px;
        }
        button:hover {
          background-color: #13dafb;
          color: #000000;
        }
      }
      .video {
        width: 100%;
        aspect-ratio: 25/14;
        border: 0;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .modalContainer {
    max-width: 90%;
    aspect-ratio: 25/14;
    background-color: white;
    border-style: solid;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
    .modal {
      background-color: #ffffff;
      .btnContainer {
        height: 40px;
        button {
          background-color: #000000;
          outline: none;
          border: 1px solid #13dafb;
          color: #13dafb;
          height: 40px;
          width: 40px;
          font-size: 33px;
        }
        button:hover {
          background-color: #13dafb;
          color: #000000;
        }
      }
      .video {
        width: 100%;
        aspect-ratio: 25/14;
        border: 0;
      }
    }
  }
}

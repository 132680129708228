@media only screen and (max-width: 359px) {
  #sideAndMain {
    display: flex;
    flex-direction: column;
    #mainContent {
      max-width: 100%;
      #popularMovies {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        overflow: scroll;
      }
      #favouriteMovies {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        overflow: scroll;
        .favouritesError {
          .errorMessage {
            padding: 10px 10px 10px 10px;
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 360px) {
  #sideAndMain {
    display: flex;
    flex-direction: column;
    #mainContent {
      max-width: 100%;

      #popularMovies {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        overflow: scroll;
      }
      #favouriteMovies {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        overflow: scroll;
        .favouritesError {
          .errorMessage {
            padding: 10px 10px 10px 10px;
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 480px) {
  #sideAndMain {
    display: flex;
    flex-direction: column;
    #mainContent {
      max-width: 100%;

      #popularMovies {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        overflow: scroll;
      }
      #favouriteMovies {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        overflow: scroll;
        .favouritesError {
          .errorMessage {
            padding: 10px 10px 10px 10px;
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  #sideAndMain {
    display: flex;
    flex-direction: column;
    #mainContent {
      max-width: 100%;

      #popularMovies {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        overflow: scroll;
      }
      #favouriteMovies {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        overflow: scroll;
        .favouritesError {
          .errorMessage {
            padding: 10px 10px 10px 10px;
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  #sideAndMain {
    display: flex;
    flex-direction: row;
    background-color: #000000;
    #mainContent {
      display: flex;
      flex-direction: column;
      width: 83vw;
      height: calc(100vh - 90px);
      border-top-left-radius: 20px;
      background-color: #ffffff;
      #popularMovies {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        overflow: scroll;
      }
      #favouriteMovies {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        overflow: scroll;
        .favouritesError {
          .errorMessage {
            padding: 10px 10px 10px 10px;
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  #sideAndMain {
    #mainContent {
      #popularMovies {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        overflow: scroll;
      }
      #favouriteMovies {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        overflow: scroll;
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  #sideAndMain {
    #mainContent {
      #popularMovies {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        overflow: scroll;
      }
      #favouriteMovies {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        overflow: scroll;
      }
    }
  }
}
